<template>
	<!-- Sidebar -->
	<aside x-cloak x-transition:enter="transition transform duration-300"
		x-transition:enter-start="-translate-x-full opacity-30  ease-in"
		x-transition:enter-end="translate-x-0 opacity-100 ease-out"
		x-transition:leave="transition transform duration-300"
		x-transition:leave-start="translate-x-0 opacity-100 ease-out"
		x-transition:leave-end="-translate-x-full opacity-0 ease-in"
		class="fixed inset-y-0 z-[1025] flex flex-col flex-shrink-0 w-80 max-h-screen transition-all transform bg-[#f5f6fc] dark:bg-[#111827] dark:border-r dark:border-gray-700 overflow-auto scrollbar-hidden pb-3"
		:class="{
			'-translate-x-full  ': !sidebarOpen,
			'shadow-lg lg:z-auto lg:static lg:shadow-none': sidebarOpen,
		}">
		<div class="mx-7 mt-5 pb-8 rounded-xl bg-white dark:bg-gray-800 relative h-[100vh]">
			<!-- sidebar header -->
			<div class="flex items-center justify-between flex-shrink-0 pl-10 p-2 h-[100px]">
				<router-link :to="{ name: 'home' }">
					<!-- <img src="/images/site-logo-light.png" width="180" alt="" /> -->
					<img src="/images/site-logo.png" alt="Logo" width="180" class="block dark:hidden" />
					<img src="/images/site-logo-light.png" alt="Logo" width="180" class="hidden dark:block" />

				</router-link>
				<button @click="toggleSidebar()" class="absolute p-2 rounded-md top-0 right-0">
					<svg class="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none"
						viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
			<!-- Sidebar links -->
			<nav class="flex-1 flex justify-between items-start flex-col gap-3">
				<ul class="px-3 space-y-3 mt-6 w-full">
					<li class="group">
						<router-link class="!font-medium !text-[#655CF8] !transition-colors" :to="{ name: 'dashboard' }"
							data-te-toggle="tooltip" title="Dashboard">
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button" data-ripple-dark="true">
								<svg class="text-[#727B9D] group-hover:text-[#42B8FE] fill-white dark:fill-gray-800"
									xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
									<path d="M7.5 1L1 4.25L7.5 7.5L14 4.25L7.5 1Z" stroke="currentColor"
										stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
									<path d="M1 10.75L7.5 14L14 10.75" stroke="currentColor" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" fill="none" />
									<path d="M1 7.5L7.5 10.75L14 7.5" stroke="currentColor" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" fill="none" />
								</svg>
								<span class="">Dashboard</span>
							</button>
						</router-link>
					</li>

					<li class="group">
						<router-link
							class="!font-medium !text-[#655CF8] !transition-colors"
							:to="{ name: 'ai-products' }"
							data-te-toggle="tooltip"
							title="AI Products"
						>
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button"
								data-ripple-dark="true"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16px"
									height="16px"
									viewBox="0 0 16 16"
									class="text-[#727B9D] group-hover:text-[#42B8FE]"
								>
									<path
										d="M10 4V2H2V10H4V6C4 4.89543 4.89543 4 6 4H10zM12 4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.89543 12 0 11.1046 0 10V2C0 0.89543 0.89543 0 2 0H10C11.1046 0 12 0.89543 12 2V4zM6 6V14H14V6H6z"
										fill="currentColor"
									/>
								</svg>
								<span class="">AI Products</span>
							</button>
						</router-link>
					</li>

					<li class="group">
						<router-link
							class="!font-medium !text-[#655CF8] !transition-colors"
							:to="{ name: 'ai-templates' }"
							data-te-toggle="tooltip"
							title="AI Templates"
						>
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button"
								data-ripple-dark="true"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 15 16"
									class="text-[#727B9D] group-hover:text-[#42B8FE]"
								>
									<path
										d="M6,12 L11,12 C11.5522847,12 12,11.5522847 12,11 L12,6 L6,6 L6,12 Z M4,12 L4,6 L2,6 L2,11 C2,11.5522847 2.44771525,12 3,12 L4,12 Z M12,3 C12,2.44771525 11.5522847,2 11,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,4 L12,4 L12,3 Z M3,0 L11,0 C12.6568542,0 14,1.34314575 14,3 L14,11 C14,12.6568542 12.6568542,14 11,14 L3,14 C1.34314575,14 0,12.6568542 0,11 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"
										fill="currentColor"
									/>
								</svg>
								<span class="">AI Templates</span>
							</button>
						</router-link>
					</li>

					<li class="group">
						<router-link
							class="!font-medium !text-[#655CF8] !transition-colors>"
							:to="{ name: 'shops' }"
							data-te-toggle="tooltip"
							title="Shops"
						>
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button"
								data-ripple-dark="true"
							>
								<svg
									class="text-[#727B9D] group-hover:text-[#42B8FE]"
									xmlns="http://www.w3.org/2000/svg"
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
								>
									<path
										d="M1 3.8L3.16667 1H11.8333L14 3.8M1 3.8V13.6C1 13.9713 1.15218 14.3274 1.42307 14.5899C1.69395 14.8525 2.06135 15 2.44444 15H12.5556C12.9386 15 13.306 14.8525 13.5769 14.5899C13.8478 14.3274 14 13.9713 14 13.6V3.8M1 3.8H14M10.3889 6.6C10.3889 7.34261 10.0845 8.0548 9.54275 8.5799C9.00098 9.105 8.26618 9.4 7.5 9.4C6.73382 9.4 5.99902 9.105 5.45725 8.5799C4.91548 8.0548 4.61111 7.34261 4.61111 6.6"
										stroke="currentColor"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<span class="">Shops</span>
							</button>
						</router-link>
					</li>

					<!-- <li class="group">
					<router-link
						class="!font-medium !text-[#655CF8] !transition-colors"
						to="/image-generation"
						data-te-toggle="tooltip"
						title="Image Generation"
					>
						<button
							class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
							type="button" data-ripple-dark="true">
							<svg class="text-[#727B9D] group-hover:text-[#42B8FE]" xmlns="http://www.w3.org/2000/svg"
								width="15" height="16" viewBox="0 0 15 16" fill="none">
								<path
									d="M1 3.8L3.16667 1H11.8333L14 3.8M1 3.8V13.6C1 13.9713 1.15218 14.3274 1.42307 14.5899C1.69395 14.8525 2.06135 15 2.44444 15H12.5556C12.9386 15 13.306 14.8525 13.5769 14.5899C13.8478 14.3274 14 13.9713 14 13.6V3.8M1 3.8H14M10.3889 6.6C10.3889 7.34261 10.0845 8.0548 9.54275 8.5799C9.00098 9.105 8.26618 9.4 7.5 9.4C6.73382 9.4 5.99902 9.105 5.45725 8.5799C4.91548 8.0548 4.61111 7.34261 4.61111 6.6"
									stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
							<span class="">Image Generation</span>
						</button>
					</router-link>
				</li> -->
					<li class="group">
						<router-link class="!font-medium !text-[#655CF8] !transition-colors"
							:to="{ name: 'product-generation' }" data-te-toggle="tooltip" title="Product Generation">
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button" data-ripple-dark="true">
								<svg class="text-[#727B9D] group-hover:text-[#42B8FE]"
									xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"
									fill="none">
									<path
										d="M1 3.8L3.16667 1H11.8333L14 3.8M1 3.8V13.6C1 13.9713 1.15218 14.3274 1.42307 14.5899C1.69395 14.8525 2.06135 15 2.44444 15H12.5556C12.9386 15 13.306 14.8525 13.5769 14.5899C13.8478 14.3274 14 13.9713 14 13.6V3.8M1 3.8H14M10.3889 6.6C10.3889 7.34261 10.0845 8.0548 9.54275 8.5799C9.00098 9.105 8.26618 9.4 7.5 9.4C6.73382 9.4 5.99902 9.105 5.45725 8.5799C4.91548 8.0548 4.61111 7.34261 4.61111 6.6"
										stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
								<span class="">Product Generation</span>
							</button>
						</router-link>
					</li>
					<li class="group">
						<router-link class="!font-medium !text-[#655CF8] !transition-colors" to="/plans"
							data-te-toggle="tooltip" title="Shops">
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button" data-ripple-dark="true">
								<svg class="text-[#727B9D] group-hover:text-[#42B8FE]"
									xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
									fill="none">
									<path
										d="M8.5 1L10.8175 5.93691L16 6.73344L12.25 10.5741L13.135 16L8.5 13.4369L3.865 16L4.75 10.5741L1 6.73344L6.1825 5.93691L8.5 1Z"
										stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
								<span class="leading-none">Plans</span>
							</button>
						</router-link>
					</li>

					<li class="group">
						<router-link class="!font-medium !text-[#655CF8] !transition-colors"
							:to="{ name: 'knowledge-base' }" data-te-toggle="tooltip" title="Billing">
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button" data-ripple-dark="true">
								<svg fill="currentColor" height="20" width="18" version="1.1" id="Layer_1"
									class="text-[#727B9D] group-hover:text-[#42B8FE]" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 508.012 508.012"
									xml:space="preserve">
									<g>
										<g>
											<path d="M242.172,0.413c-80.6,5.7-145.7,70.7-151.5,151.2c-3,41.7,9.7,82.4,35.8,114.8c30.7,38.1,47.7,84.7,47.7,131v79.6
			c0,7.8,6.3,14.1,14.1,14.1h27.3c7.5,10.2,21.9,16.9,38.3,16.9s30.8-6.7,38.3-16.9h27.3c7.8,0,14.1-6.3,14.1-14.1l0.1-82
			c0-45.3,17-90.7,47.3-127.9c23.7-29.1,36.8-65.8,36.8-103.4C417.672,69.512,337.772-6.288,242.172,0.413z M305.372,462.912h-0.1
			h-21.9c-6.8,0-12.5,4.7-13.8,11.3c-0.6,1.6-6.2,5.6-15.7,5.6s-15.1-4-15.7-5.5c-1.3-6.6-7.1-11.4-13.8-11.4h-21.9v-43.7h102.9
			V462.912z M359.072,249.312c-33.6,41.1-52.4,91.3-53.4,141.7h-37.6v-227.3l11.4,11.4c5.3,5.3,14.7,5.3,20,0l35.5-35.5
			c5.5-5.5,5.5-14.4,0-20c-5.5-5.5-14.4-5.5-20,0l-25.5,25.5l-25.5-25.4c-5.5-5.5-14.4-5.5-20,0l-25.5,25.5l-25.5-25.5
			c-5.5-5.5-14.4-5.5-20,0c-5.5,5.5-5.5,14.4,0,20l35.5,35.5c8.7,7.1,17.2,2.8,20,0l11.4-11.4v227.2h-37.6
			c-1.5-50.6-20.5-100.9-53.8-142.3c-21.6-26.7-32.1-60.5-29.6-95c4.8-66.7,58.6-120.4,125.2-125.1c79.1-5.6,145.4,57.2,145.4,135.1
			C389.472,194.812,378.672,225.212,359.072,249.312z" />
										</g>
									</g>
								</svg>
								<span class="leading-none">Knowledge Base</span>
							</button>
						</router-link>
					</li>
					<li class="group">
						<router-link class="!font-medium !text-[#655CF8] !transition-colors" :to="{ name: 'billing' }"
							data-te-toggle="tooltip" title="Billing">
							<button
								class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
								type="button" data-ripple-dark="true">
								<svg class="text-[#727B9D] group-hover:text-[#42B8FE]"
									xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
									fill="none">
									<path
										d="M10.75 2.5H12.375C12.806 2.5 13.2193 2.65804 13.524 2.93934C13.8288 3.22064 14 3.60218 14 4V14.5C14 14.8978 13.8288 15.2794 13.524 15.5607C13.2193 15.842 12.806 16 12.375 16H2.625C2.19402 16 1.7807 15.842 1.47595 15.5607C1.1712 15.2794 1 14.8978 1 14.5V4C1 3.60218 1.1712 3.22064 1.47595 2.93934C1.7807 2.65804 2.19402 2.5 2.625 2.5H4.25M5.0625 1H9.9375C10.3862 1 10.75 1.33579 10.75 1.75V3.25C10.75 3.66421 10.3862 4 9.9375 4H5.0625C4.61377 4 4.25 3.66421 4.25 3.25V1.75C4.25 1.33579 4.61377 1 5.0625 1Z"
										stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
								<span class="leading-none">Billing</span>
							</button>
						</router-link>
					</li>
					<!-- Sidebar Links... -->
				</ul>

				<div class="w-full absolute bottom-6">
					<ul class="px-3 space-y-3">
						<li class="group">
							<router-link class="!font-medium !text-[#655CF8] !transition-colors"
								:to="{ name: 'user-settings' }" data-te-toggle="tooltip" title="Settings">
								<button
									class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
									type="button" data-ripple-dark="true">
									<svg class="text-[#727B9D] group-hover:text-[#42B8FE]"
										xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
										fill="none">
										<path
											d="M10.2355 4.39673C10.0909 4.54426 10.0099 4.74262 10.0099 4.94921C10.0099 5.15581 10.0909 5.35416 10.2355 5.5017L11.4983 6.76453C11.6458 6.90914 11.8442 6.99015 12.0508 6.99015C12.2574 6.99015 12.4557 6.90914 12.6033 6.76453L15.5788 3.78899C15.9757 4.66601 16.0959 5.64316 15.9233 6.59021C15.7507 7.53727 15.2937 8.40924 14.613 9.08993C13.9323 9.77062 13.0603 10.2277 12.1133 10.4003C11.1662 10.5728 10.1891 10.4526 9.31203 10.0558L3.85819 15.5096C3.5442 15.8236 3.11834 16 2.67429 16C2.23024 16 1.80438 15.8236 1.49039 15.5096C1.1764 15.1956 1 14.7698 1 14.3257C1 13.8817 1.1764 13.4558 1.49039 13.1418L6.94423 7.68797C6.54735 6.81095 6.42719 5.8338 6.59974 4.88675C6.7723 3.93969 7.22938 3.06772 7.91007 2.38703C8.59076 1.70634 9.46273 1.24926 10.4098 1.0767C11.3568 0.904149 12.334 1.02431 13.211 1.42119L10.2434 4.38883L10.2355 4.39673Z"
											stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>

									<span class="">Settings</span>
								</button>
							</router-link>
						</li>

						<li class="group">
							<router-link class="!font-medium !text-[#655CF8] !transition-colors" to="logout"
								data-te-toggle="tooltip" title="Logout" @click="logout">
								<button
									class="flex items-center select-none w-full text-[#727B9D] gap-2 rounded-[10px] py-3 px-7 h-[42px] text-center align-middle font-medium transition-all hover:bg-[#F3F6FE] active:bg-[#F3F6FE] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none group hover:text-[#42B8FE] hover:dark:bg-gray-700"
									type="button" data-ripple-dark="true">
									<svg class="text-[#727B9D] group-hover:text-[#42B8FE]"
										xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
										fill="none">
										<path
											d="M6 16H2.66667C2.22464 16 1.80072 15.8244 1.48816 15.5118C1.17559 15.1993 1 14.7754 1 14.3333V2.66667C1 2.22464 1.17559 1.80072 1.48816 1.48816C1.80072 1.17559 2.22464 1 2.66667 1H6M11.8333 12.6667L16 8.5M16 8.5L11.8333 4.33333M16 8.5H6"
											stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>

									<span class="leading-none">Logout</span>
								</button>
							</router-link>
						</li>
						<!-- Sidebar Links... -->
					</ul>
				</div>
			</nav>
		</div>
	</aside>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { sidebarStore } from '../../store/sidebarStore'
import { session } from '../../data/session'

const store = sidebarStore()
const { toggleSidebar } = store
const { isSidebarOpen } = storeToRefs(store)

const sidebarOpen = isSidebarOpen

const logout = () => {
	session.logout.submit()
}
</script>
