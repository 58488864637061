import { createRouter, createWebHistory } from 'vue-router'
import { session } from './data/session'
// import { userResource } from '@/data/user'
import { usersStore } from './store/user'
import DashboardLayout from './components/Layouts/DashboardLayout.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/pages/Home.vue'),
	},
	{
		path: '/plans',
		name: 'plans',
		component: () => import('@/pages/Plans.vue'),
	},
	{
		name: 'login',
		path: '/login',
		component: () => import('@/pages/Login.vue'),
	},
	{
		name: 'Signup',
		path: '/signup',
		component: () => import('@/pages/Signup.vue'),
	},
	{
		name: 'stripe-success',
		path: '/stripe-success',
		component: () => import('@/pages/StripeSuccess.vue'),
	},
	{
		name: 'stripe-cancel',
		path: '/stripe-cancel',
		component: () => import('@/pages/StripeCancel.vue'),
	},
	{
		name: 'dashboardlayout',
		path: '/',
		component: DashboardLayout,
		children: [
			{
				name: 'dashboard',
				path: '/user/dashboard',
				component: () => import('@/pages/User/Dashboard.vue'),
				meta: { title: 'Dashboard' },
			},
			{
				name: 'ai-products',
				path: '/user/ai-products',
				component: () => import('@/pages/User/AIProducts.vue'),
				meta: { title: 'AI Products' },
			},
			{
				name: 'shops',
				path: '/user/shops',
				component: () => import('@/pages/User/Shops.vue'),
				meta: { title: 'Shops' },
			},
			{
				name: 'ai-templates',
				path: '/user/ai-templates',
				component: () => import('@/pages/User/AITemplates.vue'),
				meta: { title: 'AI Templates' },
			},
			{
				name: 'connect-store',
				path: '/user/ai-connect-store',
				component: () => import('@/pages/User/AIConnectStore.vue'),
				meta: { title: 'Connect Store' },
			},
			{
				name: 'template-detail',
				path: '/user/template-detail/:id',
				component: () => import('@/pages/User/TemplateDetail.vue'),
				meta: { title: 'Template Detail' },
			},
			{
				name: 'generated-templates',
				path: '/user/generated-templates',
				component: () => import('@/pages/User/GeneratedTemplates.vue'),
				meta: { title: 'Generated Templates' },
			},
			{
				name: 'generated-template-detail',
				path: '/user/generated-template-detail/:id',
				component: () => import('@/pages/User/GeneratedTemplateDetail.vue'),
				meta: { title: 'Generated Template Detail' },
			},
			{
				name: 'generated-ai-product-detail',
				path: '/generated-ai-product-detail/:id',
				component: () => import('@/pages/User/GeneratedAIProductDetail.vue'),
				meta: { title: 'Generated AI Product Detail' },
			},
			{
				name: 'product-generation',
				path: '/user/product-generation',
				component: () => import('@/pages/User/ProductGeneration.vue'),
				meta: { title: 'Product Generation' },
			},
			{
				name: 'project',
				path: '/user/project/:id',
				component: () => import('@/pages/User/Projects.vue'),
				meta: { title: 'Projects' },
			},
			{
				name: 'billing',
				path: '/user/billing',
				component: () => import('@/pages/User/Billing.vue'),
				meta: { title: 'Billing' },
			},
			{
				name:"user-settings",
				path:"/user/settings",
				component: () => import('@/pages/User/Settings.vue'),
				meta: { title: 'Settings' },
			},
			{
				name: 'knowledge-base',
				path: '/user/knowledge-base',
				component: () => import('@/pages/User/KnowledgeBase.vue'),
				meta: { title: 'Knowledge Base' },
			},
			{
				name: 'knowledge-base-detail',
				path: '/user/knowledge-base-detail/:id',
				component: () => import('@/pages/User/KnowledgeBaseDetail.vue'),
				meta: { title: 'Knowledge Base Detail' },
			}
		],
	},
]

let router = createRouter({
	history: createWebHistory('/'),
	routes,
	linkActiveClass: 'active_sidelink',
})

router.beforeEach(async (to, from, next) => {
	let isLoggedIn = session.isLoggedIn
	const {userResource} = usersStore();
	try {
		await userResource.promise
	} catch (error) {
		isLoggedIn = false
	}

	const data = await userResource.reload()
	const subscription_status = data?.subscription?.subscription_status

	const { title } = to.meta
	if (title) {
		document.title = `Rebuid AI - ${title}`
	}
	
	if (to.name === 'login' && isLoggedIn) {
		next({ name: 'home' })
	// if route is admin then redirect to app/build
	}  else if (to.name !== 'home' && to.name !== 'Signup' && to.name !== 'login' && !isLoggedIn) {
		next({ name: 'login' })
	} else if (isLoggedIn && subscription_status !== 'Active' && to.matched.some(record => record.path.startsWith('/user'))) {
        next({ name: 'home' }) // Redirect to Home or any other page for unauthorized access
    }	
	else {
		next()
	}
})

export default router
